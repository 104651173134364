<template>
    <div>
      <patent-transaction-report></patent-transaction-report>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  
  